// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
// import projectsTableData from "examples/WorkoniumTeam/data/projectsTableData";

import TeamTable from "layouts/tables/teamTable";

// For checking refresh token expired
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AuthService from "services/auth.service";
import { deletePersistedState } from "data/store";
import { signOutAction } from "data/authReducer";


function TablesCustom() {
    // const { columns, rows } = authorsTableData;
    // const { columns: prCols, rows: prRows } = projectsTableData;
    const [formSubmitted, setFormSubmitted] = useState(0);
    // console.log("formSubmitted", formSubmitted);
    const handleFormSubmit = () => {
        setFormSubmitted(formSubmitted + 1);
    //   setFormSubmitted(false);
    };

    const dispatch = useDispatch();

    useEffect(() => {

        const checkRefreshTokenExpired = AuthService.checkRefreshTokenExpired();
        // console.log("checkRefreshTokenExpired", checkRefreshTokenExpired);
    
        if(checkRefreshTokenExpired){
          AuthService.logout();
          dispatch(signOutAction());
          deletePersistedState();
        //   window.location = "/authentication/sign-in";
        }
      }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Team Members</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <TeamTable formSubmitted={formSubmitted} onFormSubmit={handleFormSubmit}/>
                        </SoftBox>
                    </Card>
                </SoftBox>
                {/* <Card>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Projects table</SoftTypography>
                    </SoftBox>
                    <SoftBox
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}
                    >
                        <Table columns={prCols} rows={prRows} />
                    </SoftBox>
                </Card> */}
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default TablesCustom;
