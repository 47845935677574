/* eslint-disable react/prop-types */
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import dayjs from 'dayjs';
import SoftBox from "components/SoftBox";
import HandleDates from "services/handleDates";

export default function BasicDateTimeField(props) {
  
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SoftBox mb={2}>
        <DateTimeField 
        name={props.name}
        defaultValue={props.defaultValue!= "NA" ? dayjs(props.defaultValue) : dayjs()}
        format="LLL"
        />
      </SoftBox>
    </LocalizationProvider>
  );
}