import moment from "moment-timezone";

class HandleDates {
    static formatDateToMs(date, format, timezone) {
        return moment.tz(date, format, timezone).valueOf(); // convert to ms
    }

    static formatDateToFormat(date, format, timezone) {
        return moment.tz(date, format, timezone).format(format);
    }

    static formatDateFromMs(date, format, timezone) {
        let formattedDate = moment(date).tz(timezone).format(format);
        return formattedDate;
    }

    static formatDateFromISO(date, format, timezone) {
        // let formattedDate = new Date(date)
        let formattedDate = moment(date).tz(timezone).format(format);

        return formattedDate

    }

    // calulate the days difference between two dates using moment.js
    static daysDifference(startDate, endDate) {
        let a = moment(endDate);
        let b = moment(startDate);
        return a.diff(b, 'days');
    }

    static secondsDifference(startDate, endDate) {
        let a = moment(endDate);
        let b = moment(startDate);
        return a.diff(b, 'seconds');
    }

}

export default HandleDates;
