/* eslint-disable react/prop-types */
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import api from "api/api";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from "examples/Tables/Table";
import FormDialog from './FormDialog';



function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

// Import your API library

const TeamTable = ({ formSubmitted, onFormSubmit }) => {
  const tableType = "Team";
  const org_id = useSelector((state) => state.userDetails.org_id);
  const role_id = useSelector((state) => state.userDetails.role_id);
  // console.log("role_id", role_id);
  const url = new URL(window.location.href);
  const API_URL = process.env.REACT_APP_HTTP_URL + url.hostname + process.env.REACT_APP_BASE_URL;
  const teamUrl = API_URL + process.env.REACT_APP_TEAM_URL;
  // console.log(rerender);

  const [rows, setRows] = useState([]);
  let columns = [];
  if (role_id <= 2) {
    [columns] = useState([
      { name: "member", align: "left" },
      { name: "function", align: "left" },
      { name: "status", align: "center" },
      { name: "shift start time", align: "center" },
      { name: "shift end time", align: "center" },
      { name: "action", align: "center" },
    ]);
  } else {
    [columns] = useState([
      { name: "member", align: "left" },
      { name: "function", align: "left" },
      { name: "status", align: "center" },
      { name: "shift start time", align: "center" },
      { name: "shift end time", align: "center" },
    ]);
  }



  useEffect(() => {
    const tableType = "Team";
    const fetchTeamMembers = async () => {
      try {
        const response = await api.get(teamUrl, { org_id });
        const teamMembers = response.data;
        // console.log("teamMembers", teamMembers);
        let formattedRows = [];

        if (role_id <= 2) {
          formattedRows = teamMembers.map((member) => ({
            "member": <Author image={team2} name={member.firstName + " " + member.lastName} email={member.email} />,
            function: <Function job={member.designation} org={member.org_name} />,
            status: (
              <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
            ),
            "shift start time": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.user_shift_start}
              </SoftTypography>
            ),
            "shift end time": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.user_shift_end}
              </SoftTypography>
            ),
            "hourly rate": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.hourly_rate}
              </SoftTypography>
            ),
            "week off": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.week_off_days && member.week_off_days.map((day) => day + " ")}
              </SoftTypography>
            ),
            action: <FormDialog member={member} formSubmitted={formSubmitted} onFormSubmit={onFormSubmit}/>,
          }));
        } else {
          formattedRows = teamMembers.map((member) => ({
            "member": <Author image={team2} name={member.firstName + " " + member.lastName} email={member.email} />,
            function: <Function job={member.designation} org={member.org_name} />,
            status: (
              <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
            ),
            "shift start time": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.user_shift_start}
              </SoftTypography>
            ),
            "shift end time": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.user_shift_end}
              </SoftTypography>
            ),
            "hourly rate": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.hourly_rate}
              </SoftTypography>
            ),
            "week off": (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {member.week_off_days && member.week_off_days.map((day) => day + " ")}
              </SoftTypography>
            ),
          }));
        }

        setRows(formattedRows);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchTeamMembers();
  }, [org_id, teamUrl, formSubmitted]);

  return (
    <Table columns={columns} rows={rows} type={tableType} />
  );
};

export default TeamTable;
