import axios from "axios";
import jwt_decode from "jwt-decode";
import { NODE_APP_BASE_URL, NODE_APP_LOGOUT_URL } from "config/variable";
import api from "api/api";

const url = new URL(window.location.href);
const API_URL = process.env.REACT_APP_HTTP_URL + url.hostname + process.env.REACT_APP_BASE_URL;

class AuthService {

    login(email, password) {
        return axios
            .post(API_URL + process.env.REACT_APP_LOGIN_URL, {
                email,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    logout() {
        
        const url = new URL(window.location.href);
        const logoutUrl = API_URL +  process.env.REACT_APP_LOGOUT_URL;

        api.post(logoutUrl)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });

        // delete userid from local storage
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");

    }

    register(email, password) {
        return axios.post(API_URL + process.env.REACT_APP_REGISTER_URL, {
            email,
            password
        });

    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    checkRefreshTokenExpired() {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            const decoded = jwt_decode(refreshToken, process.env.REACT_APP_REFRESH_TOKEN_SECRET);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                return true; // token expired
            } else {
                return false; // token not expired
            }
        } else {
            return true; // token expired
        }
    }

}

export default new AuthService();

