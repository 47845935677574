// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from '@mui/material/CircularProgress';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Images
import curved1 from "assets/images/curved-images/curved1.jpg";

//Custom
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { useSelector, useDispatch } from "react-redux";
import { checkIn, checkOut } from "data/checkInOutReducer";
import React, { useState, useEffect } from "react";
import ElapsedTimeCounter from "examples/CheckInOut/ElapsedTimeCounter";
import api from "api/api";
import AuthService from "services/auth.service";
import UserService from "services/user.service";
import { deletePersistedState } from "data/store";
import { signOutAction } from "data/authReducer";


function CheckInOut() {

    const url = new URL(window.location.href);
    const API_URL = process.env.REACT_APP_HTTP_URL + url.hostname + process.env.REACT_APP_BASE_URL;
    const CHECK_IN_URL = API_URL + process.env.REACT_APP_CHECK_IN_URL;
    const CHECK_OUT_URL = API_URL + process.env.REACT_APP_CHECK_OUT_URL;

    const firstName = useSelector((state) => state.userDetails.firstName);
    const userId = useSelector((state) => state.userDetails._id);
    const shift_id = useSelector((state) => state.userDetails.shift_id);
    const [location, setLocation] = useState(null);
    const [isProgress, setIsProgress] = useState(false);
    // console.log(userDetails);

    const ischeckIn = useSelector((state) => state.checkInOut.checkIn);
    const ischeckOut = useSelector((state) => state.checkInOut.checkOut);
    const checkInTime = useSelector((state) => state.checkInOut.checkInTime);
    const checkInLocation = useSelector((state) => state.checkInOut.checkInLocation);
    const checkInId = useSelector((state) => state.checkInOut.checkInId);
    // const [checkOutLocation, setCheckOutLocation] = useState(null);
    const [coordinates, setCoordinates] = useState({ "lat": null, "lng": null });

    const dispatch = useDispatch();

    useEffect(() => {

        if (checkInLocation) {
            console.log(checkInLocation);
            setLocation(checkInLocation);
        }

    }, []); //

    //Get current location on check in
    const getLocation = (type) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {

                const checkRefreshTokenExpired = AuthService.checkRefreshTokenExpired();
                console.log("checkRefreshTokenExpired", checkRefreshTokenExpired);

                if (checkRefreshTokenExpired) {
                    alert("Your session has expired. Please login again.");
                    AuthService.logout();
                    dispatch(signOutAction());
                    deletePersistedState();
                    window.location = "/authentication/sign-in";
                }
                const { latitude, longitude } = position.coords;
                setCoordinates({ latitude, longitude });
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
                    .then(response => response.json())
                    .then(data => {
                        if (type === "checkIn") {
                            const address = data.results[0].formatted_address;
                            SubmitCheckInToServer({ latitude, longitude }, Date.now(), address);
                            dispatch(checkIn({ checkInLocation: address, checkInCoordinates: coordinates }));
                            setLocation(address);
                            setIsProgress(false);
                        } else if (type === "checkOut") {
                            const address = data.results[0].formatted_address;
                            SubmitCheckOutToServer({ latitude, longitude }, Date.now(), address);
                            dispatch(checkOut({ checkOutLocation: address, checkOutCoordinates: coordinates }));
                            setIsProgress(false);
                        }
                    });
            });
        } else {
            console.log("Geolocation is not supported by this browser.");

        }

    };

    const SubmitCheckInToServer = (coordinates, checkInTime, location) => {
        // console.log("submit check in to server");
        const { latitude, longitude } = coordinates;
        // console.log(latitude, longitude);

        const data = {
            "user_id": userId,
            "shift_id": shift_id,
            "checkInTimeMS": checkInTime,
            "checkInLatitude": latitude,
            "checkInLongitude": longitude,
            "checkInAddress": location,
        };

        api.post(CHECK_IN_URL, data)
            .then(response => {
                dispatch(checkIn(
                    {
                        checkInTime: response.data.checkInTimeMS,
                        checkInId: response.data.insertedId
                    }));
                // console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const SubmitCheckOutToServer = (coordinates, checkOutTime, location) => {
        // console.log("submit check out to server");
        const { latitude, longitude } = coordinates;
        // console.log(latitude, longitude);

        const data = {
            "user_id": userId,
            "shift_id": shift_id,
            "checkOutTimeMS": checkOutTime,
            "checkOutLatitude": latitude,
            "checkOutLongitude": longitude,
            "checkOutAddress": location,
            "checkInId": checkInId,
            "checkInTimeMS": checkInTime,
        };

        api.post(CHECK_OUT_URL, data)
            .then(response => {
                dispatch(checkOut({ checkOutTime: response.data.checkOutTimeMS }));
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    };



    const handleCheckIn = (e) => {
        e.preventDefault(); // prevent page refresh on submit
        setIsProgress(true);
        getLocation("checkIn");

    };


    const handleCheckOut = (e) => {
        e.preventDefault(); // prevent page refresh on submit
        console.log("check out");
        setIsProgress(true);
        getLocation("checkOut");
    };

    return (
        <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: `${linearGradient(
                    rgba(gradients.dark.main, 0.85),
                    rgba(gradients.dark.state, 0.75)
                )}, url(${curved1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            })}
        >
            <SoftBox p={3} position="relative" lineHeight={0}>
                <SoftTypography variant="h5" color="white" fontWeight="medium">
                    Hello {firstName ? firstName + "!" : "User"}
                </SoftTypography>
                <SoftTypography variant="h5" color="white" fontWeight="medium">
                    {/* {!ischeckIn ? "You are not checked in" : ""} */}
                    {!ischeckIn ? "" : " " + new Date(checkInTime)}
                </SoftTypography>
                {!ischeckIn ? "" : (
                    <SoftTypography variant="h5" color="white" fontWeight="medium">
                        {location && (
                            <>
                                {/* {coordinates.latitude} &nbsp;
                                                {coordinates.longitude} */}
                                Location: {location}
                            </>
                        )}
                    </SoftTypography>
                )}

                {/* <SoftTypography variant="button" color="white" fontWeight="regular">
                    Deftones
                </SoftTypography> */}
                {!ischeckIn ? (
                    <SoftBox display="flex" mt={6} pt={1}>
                        <SoftBox display="flex" alignItems="center" justifyContent="center">
                            <SoftButton
                                variant="outlined"
                                size="medium"
                                circular
                                onClick={handleCheckIn}
                            >
                                {
                                    isProgress ? (<CircularProgress size={24} sx={{ color: "white" }} />
                                    ) : (
                                        <><AddLocationAltIcon />  &nbsp; Check In &nbsp;</>
                                    )
                                }
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                ) : (
                    <SoftBox display="flex" mt={6} pt={1}>
                        <SoftBox display="flex" alignItems="center" justifyContent="center">
                            <SoftButton
                                variant="outlined"
                                size="medium"
                                circular
                                onClick={handleCheckOut}
                            >
                                {isProgress ? (
                                    <CircularProgress size={24} sx={{ color: "white" }} />
                                ) : (
                                    <><LocationOffIcon /> &nbsp; Check Out &nbsp; </>
                                )
                                }
                            </SoftButton>
                            {/* Get time counter and display time spent after clicking Check In*/}

                            <SoftBox ml={2}>
                                <Tooltip title="Time spent" placement="top">
                                    <SoftTypography variant="h6" color="white" fontWeight="medium">
                                        {/* {localcounter == null ? "" : localcounter} &nbsp; */}
                                        {checkInTime && (
                                            <ElapsedTimeCounter serverTime={checkInTime} />
                                        )}
                                        {/* {coordinates && (
                                            <>
                                                {coordinates.latitude} &nbsp;
                                                {coordinates.longitude}
                                                location: {location}
                                            </>
                                        )} */}
                                    </SoftTypography>
                                </Tooltip>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                )}
            </SoftBox>
        </Card>
    );
}

export default CheckInOut;
