/* eslint-disable react/prop-types */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { useEffect } from 'react';
import UserService from 'services/user.service';
import { useSelector } from "react-redux";


function ComboBox({ onSelectedIdChange, compName }) {
  const [data, setData] = React.useState([]);
  const [selectedName, setSelectedName] = React.useState("");
  const [selectedId, setSelectedId] = React.useState(null);
  const role = useSelector((state) => state.userDetails.role_id);

  useEffect(() => {
    // fetch the list of users
    UserService.getTeamNameList()
      .then((response) => {

        if (role <= 2) {
          response.data = [
            { firstName: "All", lastName: "Teammates", _id: "all" },
            ...response.data
          ];
        }

        const nameList = response.data.map((user) => {
          return { label: user.firstName + " " + user.lastName, id: user._id };
        });

        setData(nameList);

        if (nameList.length === 1) {
          setSelectedName(nameList[0].label);
          setSelectedId(nameList[0].id);
          onSelectedIdChange(nameList[0].id); // Invoke the callback with the default ID
        }
        // else if (compName && compName == "Requests") {
        //   setSelectedName("All Teammates");
        //   setSelectedId("all");
        //   onSelectedIdChange("all"); // Invoke the callback with the default ID
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [onSelectedIdChange]);

  const handleNameChange = (event, value) => {
    setSelectedName(value);
    const selectedUser = data.find((user) => user.label === value);
    setSelectedId(selectedUser ? selectedUser.id : null);
    onSelectedIdChange(selectedUser ? selectedUser.id : null);
  };

  return (

    <SoftBox mb={2} sx={{ marginLeft: 2, marginBottom: 4 }}>
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        padding="15px"
        required
      >
        Select Teammate
      </SoftTypography>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data.map((user) => user.label)}
        value={selectedName}
        onChange={handleNameChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </SoftBox>

  );
}


export default (ComboBox);
