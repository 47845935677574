import { createContext } from "react";

// Workonium Context module exports

const UserContext = createContext({
    userName: "",
    setUserName: () => { }
  });

const RequestContext = createContext({
    requestData: [],
    setRequestData: () => { }
    });

export {
    UserContext,
    RequestContext
}