/* eslint-disable react/prop-types */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { useEffect } from 'react';


export function SelectSmall(props) {
  const [data, setData] = React.useState([]);
  const [selectedName, setSelectedName] = React.useState("");
  const [selectedId, setSelectedId] = React.useState(null);

  // console.log(props);

  useEffect(() => {
    // fetch the list of users

    let userData = [
      {
        label: "Leave",
        id: 1
      },
      {
        label: "Late",
        id: 2
      },
      {
        label: "Other",
        id: 3
      },      
      
    ];

    setData(userData);

  }, []);

  const handleNameChange = (event, value) => {
    setSelectedName(value);
    const selectedUser = data.find((user) => user.label === value);
    // setSelectedId(selectedUser ? selectedUser.id : null);
    // onSelectedIdChange(selectedUser ? selectedUser.id : null);
  };

  return (

    <SoftBox mb={2} >
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        padding="15px"
        required
      >
        Select Request Type
      </SoftTypography>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data.map((user) => user.label)}
        value={selectedName}
        onChange={handleNameChange}
        name={props.requestType}
        renderInput={(params) => <TextField {...params} />}
      />
    </SoftBox>

  );
}
