/* eslint-disable react/prop-types */
import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

export default function DescriptionAlerts(props) {
  let alertSeverity = 'info'; // default to info alert

  if (props.type === 'error') {
    alertSeverity = 'error';
  } else if (props.type === 'warning') {
    alertSeverity = 'warning';
  } else if (props.type === 'success') {
    alertSeverity = 'success';
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity={alertSeverity}>
        <AlertTitle>{props.type}</AlertTitle>
        {props.message}
      </Alert>
    </Stack>
  );
}

