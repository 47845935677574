import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ElapsedTimeCounter = ({ serverTime }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - serverTime;
      setElapsedTime(elapsed);
    }, 1000); // Update every second

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [serverTime]);

  // Convert elapsed time to a human-readable format
  const formatElapsedTime = (time) => {

    // Calculate hours, minutes and seconds as HH:MM:SS
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    // Format time as HH:MM:SS
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedTime;
  };

  return <>{formatElapsedTime(elapsedTime)}</>;
};

ElapsedTimeCounter.propTypes = {
    serverTime: PropTypes.number,
}

export default ElapsedTimeCounter;



