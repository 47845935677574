/* eslint-disable react/prop-types */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue, green, red, yellow } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import RequestMenu from '../RequestMenu';
import Box from '@mui/material/Box';

import { useContext } from 'react';
import { RequestContext } from "context/woContexts";
import HandleDates from "services/handleDates"
import UserService from 'services/user.service';


/**
 * create type of requests employee can make (leave, sick leave, etc.)
 * create type of requests manager can make (approve, reject, etc.)
 * edit request card to show type of request
 */


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RequestCard(props) {

    const [expanded, setExpanded] = React.useState(false);
    const [approveClicked, setApproveClicked,] = React.useState(false);
    const [rejectClicked, setRejectClicked] = React.useState(false);
    const [editClicked, setEditClicked] = React.useState(false);

    console.log(props);


    const handleApprove = (data) => {
        console.log("approve clicked");
        console.log(data);
        setApproveClicked(!approveClicked);
        UserService.postRequestApprove(data)
        .then((data) => {
            console.log("data approved")
            window.location.reload();

        })
        .catch((error) => {
            console.log(error);
    });
        // changeHandler("Tera Baap");
    };


    const handleReject = (data) => {
        console.log("reject clicked");
        console.log(data);
        setRejectClicked(!rejectClicked);
        UserService.postRequestReject(data)
        .then((data) => {
            console.log("data rejected")
            window.location.reload();

        })
        .catch((error) => {
            console.log(error);
    });
    };


    const handleEdit = () => {
        console.log("edit clicked");
        setEditClicked(!editClicked);
    };


    const requestTypes = {
        leave: {
            color: green[500],
            text: "Leave Request from",
            myCardContent: [
                <>
                    <Typography variant="body2" color="text.secondary">
                        From: {props.reqData.requestData.startDate}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        To: {props.reqData.requestData.endDate}
                    </Typography>
                </>
            ],
            myCollapseContent: [
                <>
                    <Typography paragraph>Reason:</Typography>
                    <Typography paragraph>
                        {props.reqData.requestData.requestReason}
                    </Typography>
                </>
            ],
            cardColor: "linear-gradient( 93.3deg,  rgba(236,80,80,1) 21.5%, rgba(255,97,29,1) 93.9% )",
        },
        late: {
            color: red[500],
            text: "Late Request from",
            myCardContent: [
                <>
                    {/* <Typography variant="body2" color="text.secondary">
                        Late By: {props.reqData.totalLateMinutes} minutes
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Check In: {props.reqData.expectedCheckInTime} 
                    </Typography> */}
                    <Typography variant="body2" color="text.secondary">
                        From: {props.reqData.requestData.startDate}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        To: {props.reqData.requestData.endDate}
                    </Typography>
                </>
            ],
            myCollapseContent: [
                <>
                    <Typography paragraph>Reason:</Typography>
                    <Typography paragraph>
                        {props.reqData.requestData.requestReason}
                    </Typography>
                </>
            ],
            cardColor: "linear-gradient( 135deg, #FDEB71 10%, #F8D800 100%)",
        },
        checkInOut: {
            color: yellow[500],
            text: "Shift Request from",
            myCardContent: [
                <>
                    <Typography variant="body2" color="text.secondary">
                        Check In: {HandleDates.formatDateFromMs(props.reqData.requestData.checkInTimeMS, "MMMM DD, YYYY HH:MM:ss", "Asia/Kolkata")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Check Out: {HandleDates.formatDateFromMs(props.reqData.requestData.checkOutTimeMS, "MMMM DD, YYYY HH:MM:ss", "Asia/Kolkata")}
                    </Typography>
                </>
            ],

            myCollapseContent: [
                <>
                    <Typography paragraph>Reason:</Typography>
                    <Typography paragraph>
                        {props.reqData.requestData.requestReason}
                    </Typography>
                    <Typography paragraph>Check In Address:</Typography>
                    <Typography paragraph>
                        {props.reqData.requestData.checkInAddress}
                    </Typography>
                    <Typography paragraph>Check Out Address:</Typography>
                    <Typography paragraph>
                        {props.reqData.requestData.checkOutAddress}
                    </Typography>
                </>
            ],
            cardColor: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
        },
        other: {
            color: blue[500],
            text: "Other Request from",
            myCardContent: [
                <>
                    <Typography variant="body2" color="text.secondary">
                        From: {props.reqData.requestData.startDate}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        To: {props.reqData.requestData.endDate}
                    </Typography>
                </>
            ],
            myCollapseContent: [
                <>
                    <Typography paragraph>Reason:</Typography>
                    <Typography paragraph>
                        {props.reqData.requestData.requestReason}
                    </Typography>
                </>
            ],
            cardColor: "linear-gradient( 93.3deg,  rgba(236,80,80,1) 21.5%, rgba(255,97,29,1) 93.9% )",
        },
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 350 }}>
            <Box

                sx={{
                    width: "100%",
                    height: 10,
                    background: requestTypes[props.reqData.requestType].cardColor,
                }}


            >

            </Box>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: requestTypes[props.reqData.requestType].color }} aria-label="recipe">
                        {props.reqData.userData.firstName[0]}{props.reqData.userData.lastName[0]}
                    </Avatar>
                }
                action={
                    <RequestMenu handleApprove={handleApprove} handleReject={handleReject} handleEdit={handleEdit} data={props.reqData} />
                }

                title={`${requestTypes[props.reqData.requestType].text} ${props.reqData.userData.firstName} ${props.reqData.userData.lastName}`}
                subheader={`${HandleDates.formatDateFromISO(props.reqData.requestDate, "MMMM DD, YYYY HH:MM", "Asia/Kolkata")}`}
            />
            {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}
            <CardContent>
                {props.reqData.requestType &&
                    requestTypes[props.reqData.requestType].myCardContent}
            </CardContent>
            <CardActions disableSpacing>
                {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {props.reqData.requestType &&
                        requestTypes[props.reqData.requestType].myCollapseContent}
                </CardContent>
            </Collapse>
        </Card>

    );
}
