/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";



// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
//customized
// import * as constVariables from "config/variable";

//redux store
import { useSelector, useDispatch } from "react-redux";
import { signInAction } from "data/authReducer";
import { setUserDetails } from "data/userDetailsReducer";
// Node API
import api from "api/api";
import { checkIn, resetCheckInOut } from "data/checkInOutReducer";
import { deletePersistedState } from "data/store";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";

function SignIn() {

  const url = new URL(window.location.href);
  const API_URL = process.env.REACT_APP_HTTP_URL + url.hostname + process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGIN_URL;
  console.log(API_URL);
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [error, setError] = useState("");
  const isSignedIn = useSelector((state) => state.signInOut.isSignedIn);
  const dispatch = useDispatch();
  const checkInTime = useSelector((state) => state.checkInOut.checkInTime);
  const [isProgress, setIsProgress] = useState(false);
  const navigate = useNavigate();
  

  const handleSubmit = async () => {
    setIsProgress(true);
    // console.log(email, password, rememberMe);
    deletePersistedState();
    api.post(API_URL, { email, password, rememberMe })
      .then((res) => {
        // console.log(res);
        //if response 200 then redirect to login page
        if (res.status == 200) {

          //set session id in local storage
          // console.log(res.data);

          localStorage.setItem("user", res.data.userDetails[0]._id);
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);

          // alert(checkInTime);

          if (res.data.checkInDetails != null && res.data.checkInDetails != undefined) {
            dispatch(checkIn({
              checkInTime: res.data.checkInDetails.checkInTimeMS,
              checkInLocation: res.data.checkInDetails.checkInAddress,
              checkInId: res.data.checkInDetails._id,
              checkInLocation: res.data.checkInDetails.checkInAddress
            }));

            // alert( JSON.stringify(res.data));

          } else {
            dispatch(resetCheckInOut({}));
          }
          // console.log(res.data.userDetails[0]);
          dispatch(setUserDetails(res.data.userDetails[0]));
          dispatch(signInAction({}));
          //set user details in redux store and persist redux store          
          //redirect to dashboard
          // window.location.href = "/dashboard";

          navigate("/dashboard", { replace: true });
        }
        else if (res.status == 208) {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        alert("Something went wrong. Please try again later.");
      });
  };

  return (
    <CoverLayout
      title="Welcome back"
      // description="Enter your email and password to sign in"
      image={curved9}
    >
      <SoftBox mb={2}>
        <Socials />
      </SoftBox>
      <Separator />
      {error &&
        <SoftBox mb={2}>
          <SoftTypography variant="button" fontWeight="regular" color="error">
            {error}
          </SoftTypography>
        </SoftBox>
      }
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Email"
            onChange={(e) => setEmail(e.target.value)} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" placeholder="Password"
            onChange={(e) => setPassword(e.target.value)} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>

          <SoftButton variant="gradient" color="info" fullWidth
            onClick={handleSubmit}>
            {isProgress ? (
              <CircularProgress size={24} sx={{ color: "white", justifyContent: 'center'}} />
            ) : (
              <>sign in</>
          )}
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          {/* <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography> */}
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
