/* eslint-disable react/prop-types */
import * as React from 'react';
import ButtonMui from '@mui/material/Button';
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftTypography from "components/SoftTypography";
import Box from '@mui/material/Box';
import SoftBox from 'components/SoftBox';
import Grid from '@mui/material/Grid';
import { SelectSmall } from 'examples/DropDown';
import BasicDateTimeField from 'examples/DateTime/DateAndTime';
import UserService from 'services/user.service';
import HandleDates from 'services/handleDates';
import DescriptionAlerts from 'examples/Alert';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";
import CustomDateFormat from "examples/DateTime";
import Alert from '@mui/material/Alert';



export default function RequestForm(props) {
    const [open, setOpen] = React.useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const popupRef = useRef(null);
    const org_id = useSelector((state) => state.userDetails.org_id);

    useEffect(() => {
        setAlertType('');
        setAlertMessage('');
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = (event) => {
        console.log("submit clicked");
        event.preventDefault();

        let dataToPost = {
            requestType: event.target[0].value.toLowerCase(),
            startDate : event.target[4].value,
            endDate : event.target[6].value,
            reason : event.target[8].value,
        }

        console.log(event.target[0].value);
        console.log(event.target[4].value);
        console.log(event.target[6].value);
        console.log(event.target[8].value);
        // handleClose();
        let daysDifference = HandleDates.daysDifference(event.target[4].value, event.target[6].value);

        if (event.target[0].value === "") {
            setAlertMessage("Please select a Request Type");
            return;
        }
        else if (event.target[4].value === "") {
            setAlertMessage("please enter start date");
            return;
        }
        else if (daysDifference < 0 || daysDifference > 90) {
            setAlertMessage("plese select a valid range date");
            return;
        }
        else {
            UserService.postEmployeeRequest(dataToPost)
                .then((response) => {
                    alert(JSON.stringify(response.data.message));
                    handleClose();
                })
                .catch((error) => {
                    alert("Some Error")
                })
        }



    }

    return (
        <>
            <ButtonMui variant="outlined" name="applyReq" sx={{ marginLeft: 3, marginBottom: 2 }}
                onClick={handleClickOpen}>
                <SoftTypography component="a" variant="caption" color="secondary" fontWeight="medium" required
                    sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                >
                    Apply
                </SoftTypography>
            </ButtonMui>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" >
                {/* <Card open={open} onClose={handleClose}> */}
                <DialogTitle pb={0} align='center'>Apply New Request</DialogTitle>
                {
                    alertMessage !== "" ? (

                        <Alert severity="error">{alertMessage}</Alert>
                    ) : (
                        <></>
                    )
                }
                <Box
                    component="form"
                    role="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: 'auto' },
                    }}
                    Validate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <DialogContent>
                        <DialogContentText>
                            <SoftTypography
                                component="a"
                                variant="caption"
                                color="secondary"
                                fontWeight="medium"
                            >
                                Enter Request Details
                            </SoftTypography>

                        </DialogContentText>
                        <SoftBox py={3}>
                            <Grid container spacing={1}>
                                <SelectSmall name={"requestType"} requestType={"requestType"} />
                                <CustomDateFormat />
                                <SoftBox>
                                    <SoftBox>
                                        <SoftTypography
                                            component="a"
                                            variant="caption"
                                            color="secondary"
                                            fontWeight="medium"
                                            padding="15px"
                                        >Reason
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <TextField
                                            required
                                            id={"reason"}
                                            label=""
                                            name={"reason"}
                                        />
                                    </SoftBox>
                                </SoftBox>
                            </Grid>
                        </SoftBox>
                    </DialogContent>
                    <DialogActions>
                        <ButtonMui onClick={handleClose}>Cancel</ButtonMui>
                        <ButtonMui type="submit" name="submit">
                            Submit
                        </ButtonMui>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
