import { createSlice } from '@reduxjs/toolkit';


export const signInOutReducer = createSlice({
    name: 'signInOut',
    initialState: {
        isSignedIn: false,
        role: "user",
    },
    reducers: {
        signInAction: (state, action) => {
            state.isSignedIn = true
            if(action.payload){
                state.role = action.payload.role
            }
        },
        signOutAction: (state, action) => {
            state.isSignedIn = false
            state.role = "user"
        }

    },
})

export const { signInAction, signOutAction } = signInOutReducer.actions
export default signInOutReducer.reducer
