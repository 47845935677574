import { createSlice } from '@reduxjs/toolkit';

export const checkInOutReducer = createSlice({
    name: 'checkInOut',
    initialState: {
        checkIn: false,
        checkOut: false,
        checkInTime: '',
        checkOutTime: '',
        checkInDate: '',
        checkOutDate: '',
        checkInLocation: '',
        checkOutLocation: '',
        checkInImage: '',
        checkOutImage: '',
        checkInNote: '',
        checkOutNote: '',
        checkInId: '',
        checkOutId: '',

    },
    reducers: {
        checkIn: (state, action) => {
            state.checkOut = false;
            state.checkIn = true;
            state.checkInTime = action.payload.checkInTime;
            state.checkInLocation = action.payload.checkInLocation;
            state.checkInId = action.payload.checkInId;
            // state.checkInDate = action.payload.checkInDate;
            // state.checkInLocation = action.payload.checkInLocation;
            // state.checkInCoordinates = action.payload.checkInCoordinates;
            // state.checkInImage = action.payload.checkInImage;
            // state.checkInNote = action.payload.checkInNote;
            // state.checkInId = action.payload.checkInId;
        },
        checkOut: (state, action) => {
            state.checkIn = false;
            state.checkOut = true;
            state.checkOutTime = action.payload.checkOutTime;
            state.checkOutLocation = action.payload.checkOutLocation;
            // state.checkOutDate = action.payload.checkOutDate;
            // state.checkOutLocation = action.payload.checkOutLocation;
            // state.checkOutImage = action.payload.checkOutImage;
            // state.checkOutNote = action.payload.checkOutNote;
            // state.checkOutId = action.payload.checkOutId;
        },
        resetCheckInOut: (state) => {
            state.checkIn = false;
            state.checkOut = false;
            state.checkInTime = '';
            state.checkOutTime = '';
            state.checkInDate = '';
            state.checkOutDate = '';
            state.checkInLocation = '';
            state.checkOutLocation = '';
            state.checkInImage = '';
            state.checkOutImage = '';
            state.checkInNote = '';
            state.checkOutNote = '';
            state.checkInId = '';
            state.checkOutId = '';
        }
    }
})

export const { checkIn, checkOut, resetCheckInOut } = checkInOutReducer.actions;
export default checkInOutReducer.reducer
