import api from "api/api";
import authHeader from "./auth-header";

const url = new URL(window.location.href);
const API_URL = process.env.REACT_APP_HTTP_URL + url.hostname + process.env.REACT_APP_BASE_URL;

class UserService{

    getTeamMembers(){
        return api.get(API_URL + process.env.REACT_APP_TEAM_URL, { headers: authHeader() });
    }


    getUserCheckInOutDetails(data){
        return api.get(API_URL + process.env.REACT_APP_USER_CHECKINOUT_DETAILS_URL, data);
    }

    postUserCheckInOutDetails(data){
        return api.post(API_URL + process.env.REACT_APP_USER_CHECKINOUT_DETAILS_URL, data);
    }

    setUserCheckIn(data){
        return api.post(API_URL + process.env.REACT_APP_CHECK_IN_URL, data, { headers: authHeader() });
    }

    setUserCheckOut(data){
        return api.post(API_URL + process.env.REACT_APP_CHECK_OUT_URL, data, { headers: authHeader() });
    }

    getTeamNameList(){
        return api.get(API_URL + process.env.REACT_APP_TEAM_NAME_LIST_URL);
    }

    async getLocation(data){
        let response = api.post(API_URL + process.env.REACT_APP_GOOGLE_LOCATION_URL, data);
        return response;
    }

    async postRequestChangesInCheckInOut(data){
        
        return api.post(API_URL + process.env.REACT_APP_REQ_CHANGES_IN_CHECKINOUT_URL, data);
    }

    async updateTeamMember(data){
        console.log("data", data);
        return api.post(API_URL + process.env.REACT_APP_TEAM_UPDATE_MEMBER, data);
    }

    async addTeamMember(data){
        return api.post(API_URL + process.env.REACT_APP_TEAM_ADD_MEMBER, data);
    }
    
    async postEmployeeRequest(data){
        return api.post(API_URL + process.env.REACT_APP_APPLY_REQUEST_NEW, data);
    }

    async getEmployeeRequest(data){
        return api.get(API_URL + process.env.REACT_APP_APPLY_REQUEST_NEW, data);
    }

    async postRequestApprove(data){
        return api.post(API_URL + process.env.REACT_APP_APPLY_REQUEST_APPROVE, data);
    }
    
    async postRequestReject(data){
        return api.post(API_URL + process.env.REACT_APP_APPLY_REQUEST_REJECT, data);
    }

}

export default new UserService();