// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Table as MuiTable, TextField } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import Button from '@mui/material/Button';
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import FormDialogAddMember from "examples/Tables/Table/addMember"

function Table({ columns, rows, type }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const org_id = useSelector((state) => state.userDetails.org_id);
  const userId = useSelector((state) => state.userDetails._id);
  const role = useSelector((state) => state.userDetails.role_id);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredRows = useMemo(() => {
    if (!searchQuery) {
      return rows;
    }

    const lowerCaseQuery = searchQuery.toLowerCase();

    return rows.filter((row) => {
      for (const column of columns) {
        // console.log(({"name": row["author"].props.name}));
        const cellValue = row[column.name].props.name?.toString().toLowerCase();


        if (cellValue && cellValue.includes(lowerCaseQuery)) {
          return true;
        }
      }

      return false;
    });
  }, [searchQuery, columns, rows]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderRows = filteredRows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </SoftBox>
              <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer> &nbsp; &nbsp;
        <SoftBox py={3}>
          <Grid container spacing={1} margin="10px" >
            <TextField
              label=""
              value={searchQuery}
              placeholder="Search"
              onChange={handleSearchChange}
              margin="normal"
            /> &nbsp; &nbsp;
            {
              role <= 2 &&
              <FormDialogAddMember />
            }
          </Grid>
        </SoftBox>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, filteredRows, searchQuery]
  );
}

Table.defaultProps = {
  columns: [],
  rows: [{}],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
};

export default Table;
