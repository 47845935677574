/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";

// Custom
import { useState } from "react";
import { useSelector } from "react-redux";


function BillingInformation() {
  
  //load employee data from redux store created at src/index.js
  // get state from redux store
  const employeeInfo = useSelector((state) => state.employee);
  return (
    <Card id="delete-account">
      <SoftBox pt={3} px={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Employee Information
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        {/* why this code is not executing */}
        {employeeInfo.map((employee) => {
          // console.log(employee);
          return (
          <Bill key={employee.id} name={employee.name} company={employee.company} email={employee.email} vat={employee.vat} id={employee.id}/>
          )
        })}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default BillingInformation;
