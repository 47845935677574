import { createSlice } from '@reduxjs/toolkit';


const initialEmployees = [{
    id: 1,
    name: "oliver liam",
    company: "viking burrito",
    email: "oliver@burrito.com",
    vat: "FRB1235476"
},
{
    id: 2,
    name: "lucas harper",
    company: "stone tech zone",
    email: "lucas@stone-tech.com",
    vat: "FRB1235476"
},
{
    id: 3,
    name: "ethan james",
    company: "fiber notion",
    email: "ethan@fiber.com",
    vat: "FRB1235476"
},
{
    id: 4,
    name: "oliver liam",
    company: "viking burrito",
    email: "oliver@burrito.com",
    vat: "FRB1235476"
},
{
    id: 5,
    name: "lucas harper",
    company: "stone tech zone",
    email: "lucas@stone-tech.com",
    vat: "FRB1235476"
},
{
    id: 6,
    name: "ethan james",
    company: "fiber notion",
    email: "ethan@fiber.com",
    vat: "FRB1235476"
}
]


export const employeeReducer = createSlice({
    name: 'employee',
    initialState: initialEmployees,
    reducers: {
        addEmployee: (state, action) => {
            state.push(action.payload)
        },
        deleteEmployee: (state, action) => {
            return state.filter(employee => employee.id !== action.payload)
        },
        updateEmployee: (state, action) => {
            const index = state.findIndex(employee => employee.id === action.payload.id)
            if (index !== -1) {
                state[index] = action.payload
            }
        },
    },
})

export const { addEmployee, deleteEmployee, updateEmployee } = employeeReducer.actions
export default employeeReducer.reducer
