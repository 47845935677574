/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Router, Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// // RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
import routes from "routes";
import { routesSignIn } from "routes";

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Redux Store
import { useSelector, useDispatch } from "react-redux";
import { signInAction } from "data/authReducer";

// Images
import brand from "assets/images/Workonium-flask-logos_transparent.png";

import AuthService from "services/auth.service";

import { deletePersistedState } from "data/store";
import { signOutAction } from "data/authReducer";
import PrivateRoutes from "PrivateRoutes";


export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const isSignedIn = useSelector((state) => state.signInOut.isSignedIn);
  const dispatchSignIn = useDispatch();
  const dispatchSignOut = useDispatch();

  useEffect(() => {

    const checkRefreshTokenExpired = AuthService.checkRefreshTokenExpired();
    console.log("checkRefreshTokenExpired", checkRefreshTokenExpired);

    if (checkRefreshTokenExpired) {
      AuthService.logout();
      dispatchSignOut(signOutAction());
      deletePersistedState();
      // window.location = "/authentication/sign-in";
    }
  }, []);

  // Only run once before mounting the component
  useEffect(() => {

    if (!isSignedIn) {
      //Check if access token is present in local storage
      //If present, then set isSignedIn to true
      const userId = localStorage.getItem("user");

      if (userId) {
        //set isSignedIn to true
        console.log("user", userId);
        dispatchSignIn(signInAction());
      }
    }
  }, []);

  // // Cache for the rtl
  // useMemo(() => { // useMemo is used to avoid recreating the cache on every render
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );
  // Sidenav to hide on all screens

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && isSignedIn && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Workonium"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {/* <Routes>
        {isSignedIn ? getRoutes(routes) : getRoutes(routesSignIn)}
        {isSignedIn ?
          (
            <Route path="*" element={<Navigate to="/dashboard" />} />
          ) : (
            <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
          )
        }
        </Routes> */}
      {/* <Route path="/errors/error-404" component={<div>Page Not Found</div>} />
        <Route path="*" exact={true} component={<div>Page Not Found</div>} /> */}
  
        <Routes>
          <Route element={<PrivateRoutes />}>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
          {getRoutes(routesSignIn)}
        </Routes>

    </ThemeProvider>
  );
}
