import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // or whatever storage you want to use
import { combineReducers } from 'redux';


import employeeReducer from './employeeReducer';
import checkInOutReducer from './checkInOutReducer';
import signInOutReducer from './authReducer';
import userDetailsReducer from './userDetailsReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userDetails', "checkInOut"], // Specify the reducers you want to persist
};

const rootReducer = combineReducers({
    employee: employeeReducer,
    checkInOut: checkInOutReducer,
    signInOut: signInOutReducer,
    userDetails: userDetailsReducer,
    // other reducers that you don't want to persist
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// setup a redux store using redux toolkit and initial state of employees as initialEmployees
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

});

// Function to delete persisted state
const deletePersistedState = () => {
    persistor.purge();
};

// export the persistor
export const persistor = persistStore(store);

// export the store
export { store, persistReducer, deletePersistedState };



