/* eslint-disable react/prop-types */
import { Routes, Outlet, Navigate } from 'react-router-dom';
import AuthService from 'services/auth.service';

const PrivateRoutes = ({}) => {
    let refTokenExpired =  AuthService.checkRefreshTokenExpired();
    console.log("PrivateRoutes refTokenExpired", refTokenExpired);
    return (
        !refTokenExpired ? <Outlet /> : <Navigate to="/authentication/sign-in" />
    )

};

export default PrivateRoutes;

