/* eslint-disable react/prop-types */
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import React, { useEffect, useState } from 'react';
import FormDialog from './FormDialog';
import ReportTable from "examples/Tables/Table/customReportsTable";
import UserService from "services/user.service";
import { useSelector } from 'react-redux';
import HandleDates from "services/handleDates";

function Author({ image, name, email }) {
    return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
            <SoftBox mr={2}>
                <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                    {name}
                </SoftTypography>
                <SoftTypography variant="caption" color="secondary">
                    {email}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
}

function Function({ job, org }) {
    return (
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="caption" fontWeight="medium" color="text">
                {job}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {org}
            </SoftTypography>
        </SoftBox>
    );
}

const ReportsTable = (props) => {
    const tableType = "Reports";
    const org_id = useSelector((state) => state.userDetails.org_id);
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        { name: "Check In", align: "left" },
        { name: "Check Out", align: "left" },
        { name: "Request Changes", align: "center" },
        { name: "Check In Location", align: "center" },
        { name: "Check Out Location", align: "center" },
        { name: "Total Hours", align: "center" },
        { name: "action", align: "center" },
    ]);

    // for undefined values of keys return empty string instead in teamMembers array
    // const formatTeamMembers = (member) => {

    //     return {
    //         checkInTimeMS: member.checkInTimeMS !== undefined ? HandleDates.formatDateFromMs(member.checkInTimeMS, 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : "Not Checked In",
    //         checkOutTimeMS: member.checkOutTimeMS !== undefined ? HandleDates.formatDateFromMs(member.checkOutTimeMS, 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : "Not Checked In",
    //         checkInAddress: member.checkInAddress !== undefined ? member.checkInAddress : "Not Checked In",
    //         checkOutAddress: member.checkOutAddress !== undefined ? member.checkOutAddress : "Not Checked In",
    //         totalHours: member.totalHours !== undefined ? member.totalHours : "Not Checked In",
    //     }

    // };

    const formatTeam = (teams) => {
        return (
            teams.map((team) => {
                return {
                    checkInTimeMS: team.checkInTimeMS !== undefined ? team.checkInTimeMS : "NA",
                    checkOutTimeMS: team.checkOutTimeMS !== undefined ? team.checkOutTimeMS : "NA",
                    checkInAddress: team.checkInAddress !== undefined ? team.checkInAddress : "NA",
                    checkOutAddress: team.checkOutAddress !== undefined ? team.checkOutAddress : "NA",
                    totalHours: team.totalHours !== undefined ? team.totalHours : "NA",
                    requestChanges: team.requestChanges !== undefined ? team.requestChanges : "NA",
                    reason: team.reason !== undefined ? team.reason : "",
                    checkInOut_id: team._id !== undefined ? team._id : "",
                }
            })
        )
    }


    useEffect(() => {
        const fetchCheckInOut = async () => {
            try {
                const data = {
                    startDate: props.startDate,
                    endDate: props.endDate,
                    teamMember: props.teamMember,
                };
                // console.log(JSON.stringify(data));
                const response = await UserService.postUserCheckInOutDetails(data);
                // console.log('Reached Here2');
                // console.log('response', response.data);
                let teamMembers = response.data;
                const formattedTeamMembers = formatTeam(teamMembers);
                const formattedRows = formattedTeamMembers.map((member) => ({

                    "Check In": (
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" id="checkInTimeMS">
                            {member.checkInTimeMS != "NA" ? HandleDates.formatDateFromMs(member.checkInTimeMS, 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : "Not Checked In"}
                        </SoftTypography>
                    ),
                    "Check Out": (
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" id="checkOutTimeMS">

                            {member.checkOutTimeMS != "NA" ? HandleDates.formatDateFromMs(member.checkOutTimeMS, 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : "Not Checked Out"}
                        </SoftTypography>
                    ),
                    "Request Changes": (
                        <>
                        { member.requestChanges == "NA" ?  <SoftBadge variant="gradient" badgeContent="none" color="success" size="xs" container id="RequestChanges" /> : <SoftBadge variant="gradient" badgeContent="Requested" color="error" size="xs" container id="RequestChanges" /> }
                        </>
                    ),
                    "Check In Location": (
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" id="checkInAddress">
                            {member.checkInAddress != "NA" ? member.checkInAddress : "Not Checked In"}
                        </SoftTypography>
                    ),
                    "Check Out Location": (
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" id="checkOutAddress">
                            {member.checkOutAddress != "NA" ? member.checkOutAddress : "Not Checked Out"}
                        </SoftTypography>
                    ),
                    "Total Hours": (
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" id="totalHours">
                            {member.totalHours != "NA" ? member.totalHours : "Not Checked Out"}
                        </SoftTypography>
                    ),
                    action: <FormDialog member={member} formSubmitted={props.formSubmitted} onFormSubmit={props.onFormSubmit} />,
                }));

                setRows(formattedRows);
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };
        fetchCheckInOut();

    }, [props.teamMember, props.startDate, props.endDate, props.formSubmitted]);

    return (
        <>

            <ReportTable columns={columns} rows={rows} type={tableType} />
        </>
    );
};

export default ReportsTable;
