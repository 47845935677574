/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftTypography from "components/SoftTypography";
import Box from '@mui/material/Box';
import SoftBox from 'components/SoftBox';
import Grid from '@mui/material/Grid';
import { SelectSmall } from 'examples/DropDown';
import BasicDateTimeField from 'examples/DateTime/DateAndTime';
import UserService from 'services/user.service';
import HandleDates from 'services/handleDates';
import DescriptionAlerts from 'examples/Alert';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";

export default function FormDialog({ member, formSubmitted, onFormSubmit }) {
  const [open, setOpen] = React.useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const popupRef = useRef(null);
  const org_id = useSelector((state) => state.userDetails.org_id);


  useEffect(() => {
    setAlertType('');
    setAlertMessage('');
  }, [open]);

  const scrollToTop = () => {
    if (popupRef.current) {
      window.scrollTo({
        top: popupRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    //get path of current page
    let path = window.location.pathname;

    if (path == "/reports") {

      const data = {
        "checkInTimeMS": HandleDates.formatDateToMs(event.target.checkInTimeMS.value, 'MMMM Do YYYY, h:mm:ss a', "Asia/Kolkata"),
        "checkOutTimeMS": HandleDates.formatDateToMs(event.target.checkOutTimeMS.value, 'MMMM Do YYYY, h:mm:ss a', "Asia/Kolkata"),
        "checkInAddress": event.target.checkInAddress.value,
        "checkOutAddress": event.target.checkOutAddress.value,
        "requestReason": event.target.reason.value,
        "checkInOut_id": member.checkInOut_id,
      }


      let daysDifference = HandleDates.secondsDifference(data.checkInTimeMS, data.checkOutTimeMS);
      console.log('daysDifference', daysDifference);

      if (daysDifference < 0) {
        scrollToTop();
        setAlertType('error');
        setAlertMessage('Check In Time cannot be greater than Check Out Time');
        return;
      }

      UserService.postRequestChangesInCheckInOut(data)
        .then((response) => {
          alert("Request submitted successfully");
        })
        .catch((error) => {
          console.log(error);
          alert("Error submitting request");
        });

    }

    if (path == "/teams") {

      const data = {
        "firstName": event.target.firstName.value,
        "lastName": event.target.lastName.value,
        "email": event.target.email.value,
        "designation": event.target.designation.value,
        "role_id": event.target.role_id.value,
        "org_id": org_id,
        "user_shift_start": event.target.user_shift_start.value,
        "user_shift_end": event.target.user_shift_end.value,
        "monthly_salary": event.target.monthly_salary.value,
        "daily_expected_shift_hours": event.target.daily_expected_shift_hours.value,
        "week_off_days": event.target.week_off_days.value,
        "user_status": event.target.user_status.value,
        "user_id": member._id,
        "user_group" : event.target.user_group.value,
      }

      UserService.updateTeamMember(data)
      .then((response) => {
        if (response.data.success) {
        alert("Request submitted successfully");
        } else {
          alert(`Error submitting request: ${response.data.message}` );      
        }
      })
      .catch((error) => {
        console.log(error);
        alert(`Error submitting request: ${JSON.stringify(error.response.data.errors[0])}` );
      });

    }
    onFormSubmit();
    handleClose();
  };


  const mapName = {
    // "key" : ["label", "type"]
    "firstName": ["First Name", "text"],
    "lastName": ["Last Name", "text"],
    "email": ["Email", "text"],
    // "phone": ["Phone", "text"],
    "address": ["Address", "text"],
    "designation": ["Designation", "text"],
    "role_id": ["Role", "text"],
    // "org_id": ["Organization", "text"],
    "user_shift_start": ["Shift Start Time", "time"],
    "user_shift_end": ["Shift End Time", "time"],
    "user_status": ["Account Status", "text"],
    "daily_expected_shift_hours": ["Daily Expected Hours", "text"],
    "week_off_days": ["Week Offs", "weekoffs"],
    "monthly_salary": ["Salary (Monthly)", "text"],
    "checkInAddress": ["Check In Address", "text"],
    "checkOutAddress": ["Check Out Address", "text"],
    "checkInTimeMS": ["Check In Time", "datetime"],
    "checkOutTimeMS": ["Check Out Time", "datetime"],
    "reason": ["Reason", "text"],
    "checkInOut_id": ["", "hidden"],
    user_group: ["User Group", "text"],
  }

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" >
        {/* <Card open={open} onClose={handleClose}> */}
        <DialogTitle pb={0} align='center'>Edit User</DialogTitle>
        <Box
          component="form"
          role="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 'auto' },
          }}
          Validate
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          <DialogContent>
            <DialogContentText>
              <SoftTypography
                component="a"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                Update the user details in the form below.
              </SoftTypography>
            </DialogContentText>
            <SoftBox py={3}>
              <Grid container spacing={1}>
                {Object.keys(member).map((key, index) => (
                  !(key in mapName) ? <></> :
                    <>
                      <SoftBox mb={2}>
                        <SoftTypography
                          component="a"
                          variant="caption"
                          color="secondary"
                          fontWeight="medium"
                          padding="15px"
                        >{mapName[key][0]}</SoftTypography>
                        <p></p>
                        {mapName[key][1] == "text" ? (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        ) : mapName[key][1] == "dropdown" ? (
                          <SelectSmall type={key} />
                        ) : mapName[key][1] == "time" ? (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        ) : mapName[key][1] == "weekoffs" ? (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        ) : mapName[key][1] == "datetime" ? (
                          <BasicDateTimeField defaultValue={member[key]} name={key} />
                        ) : mapName[key][1] == "hidden" ? (
                          <input type='hidden' id={key} label="" name={key} defaultValue={member[key]} />
                        ) : (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        )}
                      </SoftBox>
                    </>
                ))}
              </Grid>
              {alertType && <DescriptionAlerts type={alertType} message={alertMessage} />}
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">
              Save
            </Button>
          </DialogActions>
        </Box>
        {/* </Card> */}
      </Dialog>
    </>
  );
}