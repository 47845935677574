import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

function formatTime(value) {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
}

const minDistance = 60; // Minimum distance between slider values

export default function MinimumDistanceSlider() {
  const [value1, setValue1] = React.useState([540, 1020]); // Default values represent 06:30 - 07:30
  const [value2, setValue2] = React.useState([180, 720]); // Default values represent 06:30 - 07:30

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 600 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }
  };

  const marks = [
    { value: 0, label: '00:00' },
    { value: 360, label: '06:00' },
    { value: 720, label: '12:00' },
    { value: 1080, label: '18:00' },
    { value: 1440, label: '24:00' },
  ]; // Custom marks for 24-hour range in time format

  return (
    <Box sx={{ width: 600 }} p={3}>
      <SoftBox mb={3}>
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        padding="0px"
        fontSize="15px"
        required
      >
        Shift Start: {formatTime(value1[0])}
      </SoftTypography> &nbsp; &nbsp; 
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        padding="0px"
        fontSize="15px"
        required
      >
        Shift End: {formatTime(value1[1])}
      </SoftTypography> &nbsp; &nbsp;
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        padding="0px"
        fontSize="15px"
        required
      >
       Total Hours: {formatTime(value1[1] - value1[0])}
      </SoftTypography>
      </SoftBox>

      
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={value1}
        onChange={handleChange1}
        valueLabelDisplay="off"
        getAriaValueText={formatTime}
        step={15} // Set step to 30 to represent minutes
        marks={marks}
        min={0}
        max={1440}
        disableSwap
      />
    </Box>
  );
}
