/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import SaveIcon from '@mui/icons-material/Save';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

// Custom
import { useSelector, useDispatch } from "react-redux";
import { deleteEmployee, updateEmployee } from "data/employeeReducer";
import { useState } from "react";

function Bill({ name, company, email, vat, noGutter, id }) {

  const oldEmployee = {
    name: name,
    company: company,
    email: email,
    vat: vat,
    id: id,
  };
  const [localEmployee, setLocalEmployee] = useState(oldEmployee);
  const [editItem, setEditItem] = useState(false);

  const dispatch = useDispatch();
  const handleDelete = (e) => {
    e.preventDefault(); // prevent page refresh on submit
    console.log("delete");
    dispatch(deleteEmployee(id));
  };

  const handleEdit = (e) => {
    e.preventDefault(); // prevent page refresh on submit
    console.log("edit");

    const newEmployee = {
      name: localEmployee.name,
      company: localEmployee.company,
      email: localEmployee.email,
      vat: localEmployee.vat,
      id: id,
    };

    // handles both edit and save
    setEditItem(!editItem);
    if(editItem){
      console.log(newEmployee);
      dispatch(updateEmployee(newEmployee));
    }
  };

  return (
    <SoftBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <SoftBox width="100%" display="flex" flexDirection="column">
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          {/* // if editItem is false, show the name else show the edit form */}
          {!editItem ? (
          <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {name}
          </SoftTypography>
          ) : (
            <SoftInput
              type="text"
              placeholder="name"
              value={localEmployee.name}
              onChange={(e) => setLocalEmployee({ ...localEmployee, name: e.target.value })}
            />
          )}
          <SoftBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <SoftBox mr={1}>
              <SoftButton variant="text" color="error" onClick={handleDelete}>
                <Icon >delete</Icon>&nbsp;delete
              </SoftButton>
            </SoftBox>
            {/* // if editItem is false, show the edit button else show save */}
            {!editItem ? (
              <SoftButton variant="text" color="dark" onClick={handleEdit}>
                <Icon>edit</Icon>&nbsp;edit
              </SoftButton>
            ) : (
              <SoftButton variant="text" color="dark" onClick={handleEdit}>
              <SaveIcon></SaveIcon>&nbsp;save
            </SoftButton>
            )}

          </SoftBox>
        </SoftBox>
        <SoftBox mb={1} lineHeight={0}>
          {/* // if editItem is false, show the edit form */}
          {!editItem ? (
            <SoftTypography variant="caption" color="text">
              Company Name:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                {company}
              </SoftTypography>
            </SoftTypography>
          ) : (
            <SoftTypography variant="caption" color="text">
              Company Name:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                <SoftInput
                  type="text"
                  name="company"
                  onChange={(e) => setLocalEmployee({ ...localEmployee, company: e.target.value })}
                  value={localEmployee.company}
                />
              </SoftTypography>
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={1} lineHeight={0}>
          {/* // if editItem is false, show the edit form */}
          {!editItem ? (
          <SoftTypography variant="caption" color="text">
            Email Address:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              {email}
            </SoftTypography>
          </SoftTypography>
          ) : (
            <SoftTypography variant="caption" color="text">
            Email Address:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              <SoftInput
                type="text"
                name="email"
                onChange={(e) => setLocalEmployee({ ...localEmployee, email: e.target.value })}
                value={localEmployee.email}
              />
            </SoftTypography>
          </SoftTypography>
          )}
        </SoftBox>
        {/* // if editItem is false, show the edit form */}
        {!editItem ? (
        <SoftTypography variant="caption" color="text">
          VAT Number:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {vat}
          </SoftTypography>
        </SoftTypography>
        ) : (
          <SoftTypography variant="caption" color="text">
          VAT Number:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            <SoftInput
              type="text"
              name="vat"
              onChange={(e) => setLocalEmployee({ ...localEmployee, vat: e.target.value })}
              value={localEmployee.vat}
            />
          </SoftTypography>
        </SoftTypography>
        )}        
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  id: PropTypes.number.isRequired,
};

export default Bill;
