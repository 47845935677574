// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsTable from "layouts/tables/reportsTable";

import CustomDateFormat from "examples/DateTime";
import Box from '@mui/material/Box';
import ComboBox from "examples/AutoComplete";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import HandleDates from "services/handleDates";
import DescriptionAlerts from "examples/Alert";

// For checking refresh token expired
import { useDispatch } from "react-redux";
import AuthService from "services/auth.service";
import { deletePersistedState } from "data/store";
import { signOutAction } from "data/authReducer";

import ButtonMui from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import RequestCard from "examples/RequestCard";
import ItemG from '@mui/material/Grid';

import { useMemo } from "react";
// import { UserContext } from "context/woContexts";
import RequestForm from "examples/RequestForm";
import UserService from 'services/user.service';
import { Alert } from "@mui/material";



const Requests = () => {
    const dispatch = useDispatch();
    const org_id = useSelector((state) => state.userDetails.org_id);
    const userId = useSelector((state) => state.userDetails._id);
    const role = useSelector((state) => state.userDetails.role_id);
    const [reqData, setReqData] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [teamMember, setTeamMember] = useState("");

    const [fetchList, setFetchList] = useState(false);
    const [teamMemberId, setTeamMemberId] = useState("");
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [buttonType, setButtonType] = useState('');

    const domainUrl = new URL(window.location.href);
    const apiURL = `${process.env.REACT_APP_HTTP_URL}${domainUrl.hostname}${process.env.REACT_APP_BASE_URL}`;
    const [formSubmitted, setFormSubmitted] = useState(0);
    const handleFormSubmit = () => {
        setFormSubmitted(formSubmitted + 1);
        //   setFormSubmitted(false);
    };


    // const [reqData, setReqData] = useState(data);
    // const reqValue = useMemo(
    //     () => ({ reqData, setReqData }),
    //     [reqData]
    // );
    // console.log('reqData', reqData);


    useEffect(() => {

        const checkRefreshTokenExpired = AuthService.checkRefreshTokenExpired();

        if (checkRefreshTokenExpired) {
            AuthService.logout();
            dispatch(signOutAction());
            deletePersistedState();
            // window.location = "/authentication/sign-in";
        }
    }, []);

    const handleSubmit = (event) => {
        setAlertType('');
        setAlertMessage('');

        if (buttonType !== "submitBtn") {
            return;
        }

        event.preventDefault();
        // get the form data

        const getData = {
            params: {
                startDate: event.target[0].value,
                endDate: event.target[2].value,
                userId: teamMemberId,
            }
        };


        console.log('getData', getData);

        let daydate = HandleDates.daysDifference(event.target[0].value, event.target[2].value);

        if (event.target[0].value === "") {
            // alert("enter date")
            setAlertType('error');
            setAlertMessage('please enter start date');
            return;
        } else if (event.target[2].value === "") {
            // alert("pls select end date")
            setAlertType('error');
            setAlertMessage("please enter end date")
        } else if (event.target[4].value === "") {
            // alert("please select value")
            setAlertType('error');
            setAlertMessage("please select value")
        }
        else if (daydate > 90) {
            setAlertType('error');
            setAlertMessage('Please select a date range less than 90 days');
            return;
        }
        else if (daydate < 0) {
            console.log('daydate', daydate);
            setAlertType('error');
            setAlertMessage('Please select a valid date range');
            return;
        } else {
            UserService.getEmployeeRequest(getData)
                .then((response) => {
                    if (response.data.message && response.data.message === "No requests found") {
                        setAlertType('warning');
                        setAlertMessage('No requests found');
                    } else {
                        console.log('response', response);
                        setReqData(response.data.data)
                        setButtonType("");
                        setAlertType('');
                        setAlertMessage('');
                    }
                })
                .catch((error) => {
                    alert("Some Error Occured")
                })
        }





        // setStartDate(event.target[0].value);
        // setEndDate(event.target[2].value);

        // if (buttonType !== 'get_pdfAll' && buttonType !== 'get_excelAll') {
        //     setTeamMember(teamMemberId);
        //     setFetchList(true);
        // }
    }

    const handleSelectedIdChange = (selectedId) => {
        setTeamMemberId(selectedId);
    }

    useEffect(() => {
        if (role > 2) {
            setStartDate(new Date());
            setEndDate(new Date());
            setTeamMember(userId);
        }
    }, [role, userId]);

    return (

        <DashboardLayout>
            <DashboardNavbar />

            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Requests</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                            pb={1}

                        >


                            <Box
                                component="form"
                                role="form"
                                sx={{
                                    '& .MuiTextField-root': { ml: 1, width: '100%', mb: -1, mt: 1 },
                                }}
                                Validate
                                autoComplete="off"
                                onSubmit={handleSubmit}

                            >
                                {
                                    role > 0 ? (
                                        <>
                                            <Stack spacing={0} direction="row" sx={{ flexWrap: "wrap" }}>
                                                <CustomDateFormat />
                                                <ComboBox onSelectedIdChange={handleSelectedIdChange} compName={"Requests"} />
                                            </Stack>
                                            <Stack spacing={0} direction="row" sx={{ flexWrap: "wrap" }}>
                                                <ButtonMui type="submit" variant="outlined" name="submit" sx={{ marginLeft: 3, marginBottom: 2 }}
                                                    onClick={() => setButtonType('submitBtn')}>
                                                    <SoftTypography component="a" variant="caption" color="secondary" fontWeight="medium" required
                                                        sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                                                    >
                                                        Submit
                                                    </SoftTypography>
                                                </ButtonMui>
                                                <RequestForm />
                                            </Stack>
                                            {
                                                alertMessage !== "" ? (

                                                    <Alert severity="error">{alertMessage}</Alert>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )}
                            </Box>
                        </SoftBox>
                    </Card>
                    <br />
                    <Card>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}

                            pb={15}
                        >
                            <Box
                                component="form"
                                role="form"
                                sx={{
                                    '& .MuiTextField-root': { ml: 1, width: '100%', mb: -1, mt: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 0, md: 0 }} useFlexGap sx={{ flexWrap: "wrap" }}>
                                    {reqData && reqData.map((item) => (
                                        <ItemG key={item.id} sx={{ marginLeft: 1.5, marginBottom: 2, marginRight: 1.5 }}>

                                            <RequestCard reqData={item} />

                                        </ItemG>
                                    ))}
                                </Stack>
                            </Box>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>

    );
}

export default Requests;
