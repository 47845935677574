// This is a reducer file for user details:
import { createSlice } from '@reduxjs/toolkit';

export const userDetailsReducer = createSlice({
    name: 'userDetails',
    initialState: {
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        role_id: '',
        org_name: '',
        user_group: '',
        user_shift: '',
        user_shift_start_time: '',
        user_shift_end_time: '',
        monthly_salary: '',
        daily_expected_shift_hours: '',
        week_off_days: [],
        org_id: '',
        designation: '',

    },
    reducers: {
        setUserDetails: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    }
});

export const { setUserDetails } = userDetailsReducer.actions;
export default userDetailsReducer.reducer;





