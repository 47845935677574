// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsTable from "layouts/tables/reportsTable";

import CustomDateFormat from "examples/DateTime";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ComboBox from "examples/AutoComplete";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import HandleDates from "services/handleDates";
import DescriptionAlerts from "examples/Alert";
import Icon from "@mui/material/Icon";
import GridOnIcon from '@mui/icons-material/GridOn';

// For checking refresh token expired
import { useDispatch } from "react-redux";
import AuthService from "services/auth.service";
import { deletePersistedState } from "data/store";
import { signOutAction } from "data/authReducer";
import Item from '@mui/material/Grid';
import ButtonMui from '@mui/material/Button';
import Stack from '@mui/material/Stack';


const Reports = () => {
    const dispatch = useDispatch();
    const org_id = useSelector((state) => state.userDetails.org_id);
    const userId = useSelector((state) => state.userDetails._id);
    const role = useSelector((state) => state.userDetails.role_id);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [teamMember, setTeamMember] = useState("");

    const [fetchList, setFetchList] = useState(false);
    const [teamMemberId, setTeamMemberId] = useState("");
    const [alert, setAlert] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [buttonType, setButtonType] = useState('');

    const domainUrl = new URL(window.location.href);
    const apiURL = `${process.env.REACT_APP_HTTP_URL}${domainUrl.hostname}${process.env.REACT_APP_BASE_URL}`;
    const [formSubmitted, setFormSubmitted] = useState(0);
    const handleFormSubmit = () => {
        setFormSubmitted(formSubmitted + 1);
        //   setFormSubmitted(false);
    };
    var w = window.innerWidth;


    useEffect(() => {
        console.log(w);

        const checkRefreshTokenExpired = AuthService.checkRefreshTokenExpired();

        if (checkRefreshTokenExpired) {
            AuthService.logout();
            dispatch(signOutAction());
            deletePersistedState();
            // window.location = "/authentication/sign-in";
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setAlert('');
        setAlertMessage('');
        // get the form data
        setStartDate(event.target[0].value);
        setEndDate(event.target[2].value);
        let daysDifference = HandleDates.daysDifference(event.target[0].value, event.target[2].value);
        // console.log('daysDifference', daysDifference);

        if (daysDifference > 90) {
            setAlert('error');
            setAlertMessage('Please select a date range less than 90 days');
            return;
        } else if (daysDifference < 0) {
            console.log('daysDifference', daysDifference);
            setAlert('error');
            setAlertMessage('Please select a valid date range');
            return;
        } else if (event.target[4].value === '' && buttonType !== 'get_pdfAll' && buttonType !== 'get_excelAll') {
            setAlert('error');
            setAlertMessage('Please select a team member');
            return;
        } else if ((daysDifference > 5 || daysDifference < 0) &&  teamMemberId === "all" && buttonType !== 'get_pdf' && buttonType !== 'get_excel') {
            setAlert('error');
            setAlertMessage('Method Not Allowed. Days Difference Should be less than 5');
            return;
        }

        if (buttonType === 'get_pdf' && teamMemberId != "all") {
            window.open(`${apiURL}${process.env.REACT_APP_REPORTS_SINGLE_PDF}?org_id=${org_id}&user_id=${teamMemberId}&start_date=${event.target[0].value}&end_date=${event.target[2].value}`, '_blank');
            return;
        } else if (buttonType === 'get_excel' && teamMemberId != "all") {
            window.open(`${apiURL}${process.env.REACT_APP_REPORTS_SINGLE_EXCEL}?org_id=${org_id}&user_id=${teamMemberId}&start_date=${event.target[0].value}&end_date=${event.target[2].value}`, '_blank');
            return;
        } else if (buttonType === 'get_pdf' && teamMemberId === "all") {
            window.open(`${apiURL}${process.env.REACT_APP_REPORTS_ALL_PDF}?org_id=${org_id}&start_date=${event.target[0].value}&end_date=${event.target[2].value}`, '_blank');
            return;
        } else if (buttonType === 'get_excel' && teamMemberId === "all") {
            window.open(`${apiURL}${process.env.REACT_APP_REPORTS_ALL_EXCEL}?org_id=${org_id}&start_date=${event.target[0].value}&end_date=${event.target[2].value}`, '_blank');
            return;
        }

        if (buttonType !== 'get_pdfAll' && buttonType !== 'get_excelAll') {
            setTeamMember(teamMemberId);
            setFetchList(true);
        }
    }

    const handleSelectedIdChange = (selectedId) => {
        setTeamMemberId(selectedId);
    }

    useEffect(() => {
        if (role > 2) {
            setStartDate(new Date());
            setEndDate(new Date());
            setTeamMember(userId);
        }
    }, [role, userId]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Reports</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                            pb={25}
                        >
                            <Box
                                component="form"
                                role="form"
                                sx={{
                                    '& .MuiTextField-root': { ml: 1, width: '100%', mb: -1, mt: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                {role >= 0 ? (
                                    <>
                                        <Stack spacing={0} direction="row" sx={{ flexWrap: "wrap" }}>
                                            <CustomDateFormat />
                                            <ComboBox onSelectedIdChange={handleSelectedIdChange} />
                                        </Stack>
                                        <Stack spacing={0} direction="row" sx={{ flexWrap: "wrap" }}>
                                            <ButtonMui type="submit" variant="outlined" name="submit" sx={{ marginLeft: 3, marginBottom: 2 }}
                                                onClick={() => setButtonType('submit')}>
                                                <SoftTypography component="a" variant="caption" color="secondary" fontWeight="medium" required
                                                    sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                                                >
                                                    Submit
                                                </SoftTypography>
                                            </ButtonMui>

                                            {role <= 2 ? (
                                                <>
                                                    <ButtonMui type="submit" variant="outlined" name="get_pdf" sx={{ marginLeft: 3, marginBottom: 2, }}
                                                        onClick={() => setButtonType('get_pdf')}>
                                                        <SoftTypography component="a" variant="caption" color="secondary" fontWeight="medium" required
                                                            sx={{ marginLeft: 1.5, marginRight: 1.5 }}
                                                        >
                                                            PDF
                                                        </SoftTypography>
                                                    </ButtonMui>

                                                    {/* <ButtonMui type="submit" variant="outlined" name="get_pdfAll" sx={{ marginLeft: 3, marginBottom: 2 }}
                                                        onClick={() => setButtonType('get_pdfAll')}>
                                                        <SoftTypography component="a" variant="caption" color="secondary" fontWeight="medium" required
                                                            sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                                                        >
                                                            PDF All
                                                        </SoftTypography>
                                                    </ButtonMui> */}

                                                    <ButtonMui type="submit" variant="outlined" name="get_excel" sx={{ marginLeft: 3, marginBottom: 2 }}
                                                        onClick={() => setButtonType('get_excel')}>
                                                        <SoftTypography component="a" variant="caption" color="secondary" fontWeight="medium" required
                                                            sx={{ marginLeft: 1, marginRight: 1 }}
                                                        >
                                                            Excel
                                                        </SoftTypography>
                                                    </ButtonMui>
{/* 
                                                    <ButtonMui type="submit" variant="outlined" name="get_excelAll" sx={{ marginLeft: 3, marginBottom: 2 }}
                                                        onClick={() => setButtonType('get_excelAll')}>
                                                        <SoftTypography component="a" variant="caption" color="secondary" fontWeight="medium" required>
                                                            Excel All
                                                        </SoftTypography>
                                                    </ButtonMui> */}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Stack>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            {alert && <DescriptionAlerts type={alert} message={alertMessage} />}
                            {fetchList && <ReportsTable startDate={startDate} endDate={endDate} teamMember={teamMember} formSubmitted={formSubmitted} onFormSubmit={handleFormSubmit} />}

                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>

    );
}

export default Reports;
