
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';


export default function CustomDateFormat() {
    // default get current month and year and set to start and end
    const [start, setStart] = React.useState(dayjs().startOf('month'));
    const [end, setEnd] = React.useState(dayjs().endOf('month'));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SoftBox mb={2} sx={{ marginLeft: 2, marginBottom: 4 }}>
                <SoftTypography
                    component="a"
                    variant="caption"
                    color="secondary"
                    fontWeight="medium"
                    padding="15px"
                    name="startDateTime"
                    id="startDateTime"
                >Start Date</SoftTypography>
                <p></p>
                <DateField
                    //   label="Full letter month"
                    value={start}
                    onChange={(newValue) => setStart(newValue)}
                    format="LL"
                />
            </SoftBox>
            <SoftBox mb={2} sx={{ marginLeft: 2, marginBottom: 4 }}>
                <SoftTypography
                    component="a"
                    variant="caption"
                    color="secondary"
                    fontWeight="medium"
                    padding="15px 15px 15px 15px" // top right bottom left
                >End Date</SoftTypography>
                <p></p>
                <DateField
                    //   label="Full letter month"
                    value={end}
                    onChange={(newValue) => setEnd(newValue)}
                    format="LL"
                    name="endDateTime"
                    id="endDateTime"
                />
            </SoftBox>
        </LocalizationProvider>
    );
}
