/* eslint-disable react/prop-types */
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftTypography from "components/SoftTypography";
import Box from '@mui/material/Box';
import SoftBox from 'components/SoftBox';
import Grid from '@mui/material/Grid';
import { SelectSmall } from 'examples/DropDown';
import BasicDateTimeField from 'examples/DateTime/DateAndTime';
import UserService from 'services/user.service';
import HandleDates from 'services/handleDates';
import DescriptionAlerts from 'examples/Alert';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export default function FormDialogAddMember() {
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const popupRef = useRef(null);
  const org_id = useSelector((state) => state.userDetails.org_id);

  const member = {
    firstName: "John",
    lastName: "Doe",
    email: "jhon.doe@example.com",
    password: `${makeid(10)}`,
    designation: "Manager, HR",
    role_id: 3,
    user_group: "employee",
    user_status: 1,
    user_shift_start: "00:00:00",
    user_shift_end: "00:00:00",
    monthly_salary: 0,
    daily_expected_shift_hours: 8,
    week_off_days: "6,0",
  };

  useEffect(() => {
    setAlertType('');
    setAlertMessage('');
  }, [open]);

  const scrollToTop = () => {
    if (popupRef.current) {
      window.scrollTo({
        top: popupRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const data = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      password: event.target.password.value,
      designation: event.target.designation.value,
      role_id: event.target.role_id.value,
      user_group: event.target.user_group.value,
      user_status: event.target.user_status.value,
      user_shift_start: event.target.user_shift_start.value,
      user_shift_end: event.target.user_shift_end.value,
      monthly_salary: event.target.monthly_salary.value,
      daily_expected_shift_hours: event.target.daily_expected_shift_hours.value,
      week_off_days: event.target.week_off_days.value,
      org_id: org_id,
    };
    console.log(data);
    //get path of current page
    let path = window.location.pathname;

    UserService.addTeamMember(data)
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.success === true) {
          alert("Member added successfully");
          //refresh page
          window.location.reload();
          // setAlertType('success');
          // setAlertMessage('Member added successfully');
        } else if (response.data.success === false && response.data.message) {
          alert(response.data.message);
        }
        scrollToTop();
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          alert(JSON.stringify(error.response.data.errors));
        }
        // setAlertType('error');
        // setAlertMessage('Something went wrong');
        scrollToTop();
      });


    handleClose();
  }
  // console.log("member :" + (member));
  // console.log(Object.keys(member).length);
  // how to iterate over object

  const mapName = {
    // "key" : ["label", "type"]
    "firstName": ["First Name", "text"],
    "lastName": ["Last Name", "text"],
    "email": ["Email", "text"],
    "password": ["Password", "text"],
    // "phone": ["Phone", "text"],
    "address": ["Address", "text"],
    "designation": ["Designation", "text"],
    "role_id": ["Role", "text"],
    // "org_id": ["Organization", "text"],
    "user_shift_start": ["Shift Start Time", "time"],
    "user_shift_end": ["Shift End Time", "time"],
    "user_status": ["Account Status", "text"],
    "daily_expected_shift_hours": ["Daily Expected Hours", "text"],
    "week_off_days": ["Week Offs", "weekoffs"],
    "monthly_salary": ["Salary (Monthly)", "text"],
    // "checkInAddress": ["Check In Address", "text"],
    // "checkOutAddress": ["Check Out Address", "text"],
    // "checkInTimeMS": ["Check In Time", "datetime"],
    // "checkOutTimeMS": ["Check In Time", "datetime"],
    // "reason": ["Reason", "text"],
    // "checkInOut_id": ["", "hidden"],
    user_group: ["User Group", "text"],
  }

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      </Button> */}
      <SoftBox mb={0} pt={2} onClick={handleClickOpen}>
        <Button variant="outlined" type="submit" mb={0} pb={0} name="add_member">
          <SoftBox display="flex" alignItems="center" lineHeight={0} ml={0} py={0} sx={{ cursor: "pointer" }}>
            <SoftTypography variant="button" fontWeight="bold" py={0} fontSize={"10px"}>
              &nbsp;Add Member
            </SoftTypography>
          </SoftBox>
        </Button>
      </SoftBox>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        {/* <Card open={open} onClose={handleClose}> */}
        <DialogTitle pb={0} align='center'>Add User</DialogTitle>
        <Box
          component="form"
          role="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 'auto' },
          }}
          Validate
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          <DialogContent>
            <DialogContentText>
              <SoftTypography
                component="a"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                Update the user details in the form below to add Member to your Organization.
              </SoftTypography>
            </DialogContentText>
            <SoftBox py={3}>
              <Grid container spacing={1}>
                {Object.keys(member).map((key, index) => (
                  !(key in mapName) ? <></> :
                    <>
                      <SoftBox mb={2}>
                        <SoftTypography
                          component="a"
                          variant="caption"
                          color="secondary"
                          fontWeight="medium"
                          padding="15px"
                        >{mapName[key][0]}</SoftTypography>
                        <p></p>
                        {mapName[key][1] == "text" ? (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        ) : mapName[key][1] == "dropdown" ? (
                          <SelectSmall type={key} />
                        ) : mapName[key][1] == "time" ? (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        ) : mapName[key][1] == "weekoffs" ? (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        ) : mapName[key][1] == "datetime" ? (
                          <BasicDateTimeField defaultValue={member[key]} name={key} />
                        ) : mapName[key][1] == "hidden" ? (
                          <input type='hidden' id={key} label="" name={key} defaultValue={member[key]} />
                        ) : (
                          <TextField
                            required
                            id={key}
                            label=""
                            name={key}
                            defaultValue={key == "checkInTimeMS" || key == "checkOutTimeMS" ? HandleDates.formatDateFromMs(member[key], 'DD-MM-YYYY HH:mm:ss', "Asia/Kolkata") : member[key]}
                          />
                        )}
                      </SoftBox>
                    </>
                ))}
              </Grid>
              {alertType && <DescriptionAlerts type={alertType} message={alertMessage} />}
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">
              Add Member
            </Button>
          </DialogActions>
        </Box>
        {/* </Card> */}
      </Dialog>
    </>
  );
}