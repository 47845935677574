/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const reportsBarChartData = {
  chart: {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Salary", data: [30000, 35000, 40000, 45000, 45000, 50000, 45000, 35000, 25500] },
  },
  items: [
    // {
    //   icon: { color: "primary", component: "library_books" },
    //   label: "users",
    //   progress: { content: "36K", percentage: 60 },
    // },
    // {
    //   icon: { color: "info", component: "touch_app" },
    //   label: "clicks",
    //   progress: { content: "2M", percentage: 90 },
    // },
    // {
    //   icon: { color: "warning", component: "payment" },
    //   label: "sales",
    //   progress: { content: "$435", percentage: 30 },
    // },
    // {
    //   icon: { color: "error", component: "extension" },
    //   label: "items",
    //   progress: { content: "43", percentage: 50 },
    // },
  ],
};

export default reportsBarChartData;
