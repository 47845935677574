// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


// For checking refresh token expired
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AuthService from "services/auth.service";
import { deletePersistedState } from "data/store";
import { signOutAction } from "data/authReducer";
import MinimumDistanceSlider from "examples/MinDistanceSlider";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';


function ShiftScheduler() {
    // const { columns, rows } = authorsTableData;
    // const { columns: prCols, rows: prRows } = projectsTableData;

    const [numShfts, setNumShifts] = useState(1);

    const dispatch = useDispatch();
    useEffect(() => {

        const checkRefreshTokenExpired = AuthService.checkRefreshTokenExpired();
        // console.log("checkRefreshTokenExpired", checkRefreshTokenExpired);
        if (checkRefreshTokenExpired) {
            AuthService.logout();
            dispatch(signOutAction());
            deletePersistedState();
            //   window.location = "/authentication/sign-in";
        }
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={0}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Schedule Shifts</SoftTypography>
                        </SoftBox>
                        <Grid container spacing={{ xs: 2, md: 4 }} >
                            <Grid pr={0} item xs={3} sm={3} md={3}>
                                <Item pl={3} >
                                    <TextField
                                        // required
                                        id={"1"}
                                        label="Shift"
                                        name={"1"}
                                        defaultValue="Shift Name"
                                        placeholder="Shift 1"
                                        variant="standard"
                                        color="secondary"
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <Item pl={3} pt={1.5}>
                                    <Button variant="outlined" type="submit" mb={0} pb={0} name="add_member">
                                        <SoftTypography variant="button"   fontWeight="bold" py={0} pl={3}  pr={4} fontSize={"12px"}>
                                            &nbsp;Add Shifts
                                        </SoftTypography>
                                    </Button>
                                </Item>
                            </Grid>
                        </Grid>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pl={3}>
                            <MinimumDistanceSlider />
                        </SoftBox>

                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ShiftScheduler;
